<template>
  <div class="status-cards-block">
    <div class="title">
      TRUE Status cards
    </div>
    <div class="text">
      Access to exclusive features, utilities, pre&#8209;sales and NFT drops on our platform.
    </div>
    <div class="buttons">
      <router-link :to="{ name: 'statuses' }" class="more">Learn more</router-link>
      <router-link :to="{ name: 'collection', params: { id: 'true-status-cards' } }" class="buy">Buy</router-link>
    </div>
    <div class="cards">
      <router-link :to="{name: 'nft', params: { id: 'bronze' }}" class="cards-item"></router-link>
      <router-link :to="{name: 'nft', params: { id: 'silver' }}" class="cards-item"></router-link>
      <router-link :to="{name: 'nft', params: { id: 'gold' }}" class="cards-item"></router-link>
      <router-link :to="{name: 'nft', params: { id: 'platinum' }}" class="cards-item"></router-link>
      <router-link :to="{name: 'nft', params: { id: 'black' }}" class="cards-item"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'status-cards-block',
}
</script>

<style lang="less" scoped>
.status-cards-block {
  position: relative;
  z-index: 2;
  > .title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("../images/bg-status.png");
    background-position: top center;
    background-size: 744px;
    background-repeat: no-repeat;
    min-height: 158px;
    font-size: 60px;
    line-height: 75px;
    font-weight: 800;
    color: #fff;
    padding-top: 143px;
    margin-bottom: 4px;
  }
  > .text {
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    color: #FFF;
    margin-bottom: 21px;
  }
  > .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 78px;
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 60px;
      border-radius: 100px;
      transition: all 0.2s;
      color: #FFF;
      font-size: 16px;
      line-height: 24px;
      &.more {
        border: 1px solid rgba(255,255,255,0.2);
        &:hover {
          border: 1px solid rgba(255,255,255,1);
        }
      }
      &.buy {
        font-weight: 700;
        background: linear-gradient(85.73deg, #FCB42F -25.39%, #CB34E3 114.43%);
        &:hover {
          background: linear-gradient(85.73deg, #fce72f -25.39%, #df52f5 114.43%);
        }
      }
    }
  }
  > .cards {
    display: flex;
    justify-content: space-between;
    &:hover {
      > .cards-item {
        opacity: 0.8;
      }
    }
    > .cards-item {
      position: relative;
      top: 0;
      width: 228px;
      height: 228px;
      background-size: contain;
      &:hover {
        top: -10px;
        cursor: pointer;
        opacity: 1;
      }
      &:nth-child(1) {
        background-image: url("../images/cards/bg-bronze.png");
      }
      &:nth-child(2) {
        background-image: url("../images/cards/bg-silver.png");
      }
      &:nth-child(3) {
        background-image: url("../images/cards/bg-gold.png");
      }
      &:nth-child(4) {
        background-image: url("../images/cards/bg-platinum.png");
      }
      &:nth-child(5) {
        background-image: url("../images/cards/bg-black.png");
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  .status-cards-block {
    > .title {
      background-size: 540px;
      min-height: 161px;
      font-size: 40px;
      line-height: 60px;
      padding-top: 101px;
      margin-bottom: 1px;
    }
    > .text {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 25px;
    }
    > .buttons {
      margin-bottom: 50px;
      > a {
        width: 160px;
        height: 50px;
        font-size: 14px;
        line-height: 21px;
      }
    }
    > .cards {
      > .cards-item {
        width: 160px;
        height: 160px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .status-cards-block {
    > .title {
      background-size: 440px;
      min-height: 134px;
      font-size: 34px;
      line-height: 51px;
      padding-top: 83px;
      margin-bottom: 0;
    }
    > .text {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 21px;
    }
    > .buttons {
      margin-bottom: 40px;
      > a {
        width: 130px;
        height: 40px;
        font-size: 13px;
        line-height: 20px;
      }
    }
    > .cards {
      > .cards-item {
        width: 128px;
        height: 128px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .status-cards-block {
    display: flex;
    flex-direction: column;
    > .title {
      order: 1;
      background-size: 320px;
      min-height: 100px;
      font-size: 26px;
      line-height: 39px;
      padding-top: 61px;
      margin-bottom: 0;
    }
    > .text {
      order: 1;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      margin-bottom: 24px;
    }
    > .buttons {
      order: 4;
      margin-bottom: 0px;
      > a {
        width: 170px;
        height: 50px;
        font-size: 14px;
        line-height: 21px;
      }
    }
    > .cards {
      order: 3;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin-bottom: 24px;
      > .cards-item {
        width: 120px;
        height: 120px;
      }
    }
  }
}
@media screen and (max-width: 429px) {
  .status-cards-block {
    display: flex;
    flex-direction: column;
    > .title {
      order: 1;
      background-size: 250px;
      min-height: 81px;
      font-size: 20px;
      line-height: 30px;
      padding-top: 51px;
      margin-bottom: 3px;
    }
    > .text {
      order: 1;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      padding: 0 15px;
    }
    > .buttons {
      order: 4;
      margin-bottom: 0px;
      > a {
        width: 130px;
        height: 40px;
        font-size: 13px;
        line-height: 20px;
      }
    }
    > .cards {
      order: 3;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin-bottom: 24px;
      > .cards-item {
        width: 90px;
        height: 90px;
      }
    }
  }
}
</style>
