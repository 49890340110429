<template>
  <div class="header-block">
    <div class="container">
      <div class="title">
        Redefining Gaming
      </div>
      <div class="cards">
        <div class="cards-item scroll-click">
          <div class="title">
            <span>TRUE</span>
            Games
          </div>
          <div class="line"></div>
          <div class="text">
            <p>Captivating, extraordinary gaming adventures</p>
            <div class="arrow-down"></div>
          </div>
        </div>
        <div class="cards-item scroll-click">
          <div class="title">
            <span>TRUE</span>
            App
          </div>
          <div class="line"></div>
          <div class="text">
            <p>Blockchain Solutions for Gaming and Beyond</p>
            <div class="arrow-down"></div>
          </div>
        </div>
        <div class="cards-item scroll-click">
          <div class="title">
            <span>TRUE</span>
            World
          </div>
          <div class="line"></div>
          <div class="text">
            <p>SocialFi Gaming Meta</p>
            <div class="arrow-down"></div>
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media-item">
          <img :src="require('../images/media/Cointelegraph.svg')" alt="Cointelegraph">
        </div>
        <div class="media-item">
          <img :src="require('../images/media/Benzinga.svg')" alt="Benzinga">
        </div>
        <div class="media-item">
          <img :src="require('../images/media/Cryptorank.svg')" alt="Cryptorank">
        </div>
        <div class="media-item">
          <img :src="require('../images/media/Yahoo.svg')" alt="Beincrypto">
        </div>
        <div class="media-item">
          <img :src="require('../images/media/Beincrypto.svg')" alt="Yahoo finance">
        </div>
        <div class="media-item">
          <img :src="require('../images/media/AIBC.svg')" alt="Sigma">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-block',
}
</script>

<style lang="less" scoped>
  .header-block {
    padding-bottom: 90px;
    background-image: url("../images/bg-header.png");
    background-position: top center;
    background-size: 2560px;
    background-repeat: no-repeat;

    > .container {
      > .title {
        font-size: 56px;
        line-height: 84px;
        font-weight: 700;
        color: #fff;
        padding-top: 332px;
        margin-bottom: 84px;
        text-align: center;
      }
      > .cards {
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        > .cards-item {
          position: relative;
          width: 380px;
          height: 250px;
          padding: 37px 40px 35px 40px;
          background: #FFFFFF19;
          backdrop-filter: blur(55px);
          border-radius: 30px;
          color: #FFF;
          top: 0;
          transition: all 0.2s;
          &:hover {
            top: -10px;
            cursor: pointer;
            background: #FFFFFF29;
            > .text > .arrow-down:before {
              animation: move 0.5s infinite linear;
            }
          }
          > .title {
            font-size: 40px;
            font-weight: 700;
            line-height: 42px;
            margin-bottom: 44px;
            > span {
              display: block;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }
          }
          > .line {
            position: relative;
            height: 1px;
            background: rgba(255, 255, 255, 0.2);
            margin-bottom: 19px;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 40px;
              height: 2px;
            }
          }
          > .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > p {
              display: block;
              max-width: 214px;
              font-size: 16px;
              line-height: 24px;
            }
            > .arrow-down {
              position: relative;
              width: 14px;
              height: 24px;
              //background-image: url("../images/scroll-down.svg");
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url("../images/scroll-down.svg");
                background-size: 100%;
              }

              @keyframes move {
                0% {transform: translateY(  0%);}
                50% {transform: translateY(20%);}
                100% {transform: translateY(  0%);}
              }
            }
          }
          &:nth-child(1) {
            box-shadow: 0px -2px 0px 0px rgba(180,255,197,1);
            > .line {
              &:before {
                background: rgba(180,255,197,1);
              }
            }
          }
          &:nth-child(2) {
            box-shadow: 0px -2px 0px 0px rgba(255,154,202,1);
            > .line {
              &:before {
                background: rgba(255,154,202,1);
              }
            }
          }
          &:nth-child(3) {
            box-shadow: 0px -2px 0px 0px rgba(103,172,255,1);
            > .line {
              &:before {
                background: rgba(103,172,255,1);
              }
            }
          }
        }
      }
      > .media {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .media-item {
          > img {
            height: 50px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .header-block {
      padding-bottom: 84px;
      background-size: 2186px;
      > .container {
        > .title {
          font-size: 46px;
          line-height: 69px;
          margin-bottom: 69px;
          padding-top: 282px;
        }
        > .cards {
          margin-bottom: 60px;
          > .cards-item {
            width: 272px;
            height: 220px;
            padding: 28px 30px 31px 30px;
            > .title {
              font-size: 32px;
              line-height: 34px;
              margin-bottom: 37px;
              > span {
                font-size: 15px;
                line-height: 22px;
              }
            }
            > .text {
              > p {
                max-width: 190px;
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
        > .media {
          > .media-item {
            > img {
              height: 36px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .header-block {
      padding-bottom: 40px;
      background-size: 1730px;
      > .container {
        > .title {
          font-size: 36px;
          line-height: 54px;
          margin-bottom: 60px;
          padding-top: 226px;
        }
        > .cards {
          margin-bottom: 50px;
          > .cards-item {
            width: 216px;
            height: 190px;
            padding: 25px 20px 21px 20px;
            border-radius: 20px;
            > .title {
              font-size: 26px;
              line-height: 28px;
              margin-bottom: 40px;
              > span {
                font-size: 14px;
                line-height: 21px;
              }
            }
            > .line {
              margin-bottom: 15px;
              &:before {
                width: 30px;
              }
            }
            > .text {
              > p {
                max-width: 160px;
                font-size: 12px;
                line-height: 18px;
              }
              > .arrow-down {
                width: 10px;
                height: 18px;
              }
            }
          }
        }
        > .media {
          > .media-item {
            > img {
              height: 30px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .header-block {
      padding-bottom: 60px;
      background-size: 1008px;
      > .container {
        > .title {
          font-size: 32px;
          line-height: 34px;
          margin-bottom: 20px;
          padding-top: 203px;
        }
        > .cards {
          margin-bottom: 36px;
          flex-wrap: wrap;
          gap: 10px;
          > .cards-item {
            width: calc(50% - 5px);
            height: 180px;
            padding: 19px 16px 18px 19px;
            border-radius: 20px;
            > .title {
              font-size: 26px;
              line-height: 28px;
              margin-bottom: 6px;
              > span {
                font-size: 14px;
                line-height: 21px;
              }
            }
            > .line {
              display: none;
            }
            > .text {
              align-items: flex-end;
              > p {
                font-size: 14px;
                line-height: 21px;
                color: #FFFFFFCC;
                flex: 1;
                padding-right: 20px;
              }
              > .arrow-down {
                width: 12px;
                height: 22px;
                &:before {
                  background-image: url("../images/scroll-down-mobile.svg");
                }
              }
            }
            &:nth-child(3) {
              width: 100%;
              height: 116px;
              > .text > p {
                max-width: 100%;
              }
            }
          }
        }
        > .media {
          flex-wrap: wrap;
          justify-content: center;
          gap: 4px;
          > .media-item {
            > img {
              height: 36px;
              margin: 0 4px 0 4px;
            }
            &:nth-child(1) {
              order: 2;
            }
            &:nth-child(2) {
              order: 1;
            }
            &:nth-child(3) {
              order: 3;
            }
            &:nth-child(4) {
              order: 4;
            }
            &:nth-child(5) {
              order: 5;
            }
            &:nth-child(6) {
              order: 6;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .header-block {
      padding-bottom: 50px;
      background-image: url("../images/bg-header-mobile.png");
      background-size: 806px;
      > .container {
        > .title {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 15px;
          padding-top: 157px;
        }
        > .cards {
          margin-bottom: 36px;
          flex-wrap: wrap;
          gap: 10px;
          > .cards-item {
            width: calc( 50% - 5px );
            height: 154px;
            padding: 18px 14px 18px 20px;
            border-radius: 16px;
            > .title {
              font-size: 20px;
              line-height: 22px;
              margin-bottom: 6px;
              > span {
                font-size: 11px;
                line-height: 16px;
              }
            }
            > .line {
              display: none;
            }
            > .text {
              align-items: flex-end;
              > p {
                font-size: 12px;
                line-height: 18px;
                color: #FFFFFFCC;
                flex: 1;
              }
              > .arrow-down {
                width: 10px;
                height: 18px;
                margin-bottom: 5px;
                &:before {
                  background-image: url("../images/scroll-down-mobile.svg");
                }
              }
            }
            &:nth-child(3) {
              width: 100%;
              height: 98px;
              > .text {
                > p {
                  max-width: 100%;
                }
                > img {
                  width: 10px;
                  height: 18px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        > .media {
          flex-wrap: wrap;
          justify-content: center;
          gap: 4px;
          > .media-item {
            > img {
              height: 27px;
              margin: 0 3px 0 3px;
            }
            &:nth-child(1) {
              order: 2;
            }
            &:nth-child(2) {
              order: 1;
            }
            &:nth-child(3) {
              order: 3;
            }
            &:nth-child(4) {
              order: 4;
            }
            &:nth-child(5) {
              order: 5;
            }
            &:nth-child(6) {
              order: 6;
            }
          }
        }
      }
    }
  }
</style>
