<template>
  <div class="projects-block">
    <div class="title">
      Projects that already work 🚀
    </div>
    <div class="cards">
      <a href="https://true.world/" target="_blank" class="cards-item">
        <div class="title">
          TRUE World
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.57574 10.5757C1.34142 10.8101 1.34142 11.1899 1.57574 11.4243C1.81005 11.6586 2.18995 11.6586 2.42426 11.4243L1.57574 10.5757ZM11.6 2C11.6 1.66863 11.3314 1.4 11 1.4L5.6 1.4C5.26863 1.4 5 1.66863 5 2C5 2.33137 5.26863 2.6 5.6 2.6H10.4V7.4C10.4 7.73137 10.6686 8 11 8C11.3314 8 11.6 7.73137 11.6 7.4L11.6 2ZM2.42426 11.4243L11.4243 2.42426L10.5757 1.57574L1.57574 10.5757L2.42426 11.4243Z" fill="white" fill-opacity="0.4"/>
          </svg>
        </div>
        <div class="text">SocialFi Gaming Meta</div>
      </a>
<!--      <a href="https://iguild.io/" target="_blank" class="cards-item">-->
<!--        <div class="title">-->
<!--          iGuild-->
<!--          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M1.57574 10.5757C1.34142 10.8101 1.34142 11.1899 1.57574 11.4243C1.81005 11.6586 2.18995 11.6586 2.42426 11.4243L1.57574 10.5757ZM11.6 2C11.6 1.66863 11.3314 1.4 11 1.4L5.6 1.4C5.26863 1.4 5 1.66863 5 2C5 2.33137 5.26863 2.6 5.6 2.6H10.4V7.4C10.4 7.73137 10.6686 8 11 8C11.3314 8 11.6 7.73137 11.6 7.4L11.6 2ZM2.42426 11.4243L11.4243 2.42426L10.5757 1.57574L1.57574 10.5757L2.42426 11.4243Z" fill="white" fill-opacity="0.4"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--        <div class="text">Gaming Lovers (Crypto Community)</div>-->
<!--      </a>-->
      <a href="https://nft.uventy.com/" target="_blank" class="cards-item">
        <div class="title">
          Uventy
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.57574 10.5757C1.34142 10.8101 1.34142 11.1899 1.57574 11.4243C1.81005 11.6586 2.18995 11.6586 2.42426 11.4243L1.57574 10.5757ZM11.6 2C11.6 1.66863 11.3314 1.4 11 1.4L5.6 1.4C5.26863 1.4 5 1.66863 5 2C5 2.33137 5.26863 2.6 5.6 2.6H10.4V7.4C10.4 7.73137 10.6686 8 11 8C11.3314 8 11.6 7.73137 11.6 7.4L11.6 2ZM2.42426 11.4243L11.4243 2.42426L10.5757 1.57574L1.57574 10.5757L2.42426 11.4243Z" fill="white" fill-opacity="0.4"/>
          </svg>
        </div>
        <div class="text">Blockchain ticketing solution</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'projects-block',
}
</script>

<style lang="less" scoped>
  .projects-block {
    margin-bottom: 100px;

    > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      line-height: 39px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 50px;
      &:before {
        content: '';
        width: 340px;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      }
      &:after {
        content: '';
        width: 340px;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
    > .cards {
      display: flex;
      justify-content: center;
      gap: 20px;
      > .cards-item {
        position: relative;
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        width: 380px;
        height: 370px;
        padding: 0 30px 36px 30px;
        background-color: #FFFFFF1A;
        border-radius: 30px;
        color: #fff;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        &:hover {
          top: -10px;
          cursor: pointer;
          background-color: #FFFFFF29;
        }
        > .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          line-height: 31px;
          font-weight: 700;
          margin-bottom: 3px;
          gap: 8px;
        }
        > .text {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: rgba(255,255,255,0.6);
        }
        &:nth-child(1) {
          background-image: url("../images/bg-true-world.png");
        }
        //&:nth-child(2) {
        //  background-image: url("../images/bg-iguild.png");
        //}
        &:nth-child(2) {
          background-image: url("../images/bg-uventy.png");
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .projects-block {
      margin-bottom: 90px;

      > .title {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 30px;
        &:before {
          content: '';
          width: 221px;
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
        }
        &:after {
          content: '';
          width: 221px;
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
      > .cards {
        display: flex;
        justify-content: center;
        gap: 15px;
        > .cards-item {
          position: relative;
          top: 0;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: column;
          width: 272px;
          height: 265px;
          padding: 0 30px 23px 25px;
          border-radius: 20px;
          > .title {
            font-size: 18px;
            line-height: 23px;
          }
          > .text {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .projects-block {
      margin-bottom: 81px;

      > .title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 30px;
        &:before {
          width: 183px;
        }
        &:after {
          width: 183px;
        }
      }
      > .cards {
        display: flex;
        justify-content: center;
        gap: 10px;
        > .cards-item {
          width: 216px;
          height: 224px;
          padding: 0 20px 22px 20px;
          border-radius: 20px;
          > .title {
            font-size: 16px;
            line-height: 20px;
          }
          > .text {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .projects-block {
      margin-bottom: 60px;

      > .title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 19px;
        justify-content: center;
        &:before, &:after {
          display: none;
        }
      }
      > .cards {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 8px;
        > .cards-item {
          position: relative;
          width: 100%;
          height: 144px;
          padding: 23px 25px 22px 200px;
          background-color: transparent;
          background-position: 20px 20px;
          background-size: 160px;
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          justify-content: flex-start;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 0;
          }
          > .title {
            font-size: 16px;
            line-height: 21px;
            gap: 6px;
            > svg {
              width: 8px;
              height: 8px;
            }
          }
          > .text {
            font-size: 14px;
            line-height: 18px;
          }

          &:nth-child(1) {
            background-image: url("../images/bg-true-world-mobile.png");
          }
          //&:nth-child(2) {
          //  background-image: url("../images/bg-iguild-mobile.png");
          //}
          &:nth-child(2) {
            background-image: url("../images/bg-uventy-mobile.png");
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .projects-block {
      margin-bottom: 55px;

      > .title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
        justify-content: center;
        &:before, &:after {
          display: none;
        }
      }
      > .cards {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 8px;
        > .cards-item {
          position: relative;
          width: 100%;
          height: 130px;
          padding: 24px 14px 22px 170px;
          background-color: transparent;
          background-position: 10px 10px;
          background-size: 144px;
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          justify-content: flex-start;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
            z-index: 0;
          }
          > .title {
            font-size: 14px;
            line-height: 18px;
            gap: 6px;
            > svg {
              width: 8px;
              height: 8px;
            }
          }
          > .text {
            font-size: 12px;
            line-height: 15px;
          }

          &:nth-child(1) {
            background-image: url("../images/bg-true-world-mobile.png");
          }
          //&:nth-child(2) {
          //  background-image: url("../images/bg-iguild-mobile.png");
          //}
          &:nth-child(2) {
            background-image: url("../images/bg-uventy-mobile.png");
          }
        }
      }
    }
  }
</style>
