<template>
  <div class="about-block">
    <div class="title">
      TRUE
    </div>
    <div class="igaming">
      <div class="text">
        TRUE includes two primary business areas: a <strong>Game development studio</strong> and a <strong>Blockchain powered solution</strong> which has developed <strong>SocialFi Gaming Meta</strong>.
      </div>
      <div class="timeline">
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            6 years of expertise in creating games and blockchain solutions
          </div>
        </div>
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            A working product
          </div>
        </div>
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            Confirmed traction
          </div>
        </div>
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            Growing revenue throughout the bear market
          </div>
        </div>
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            The break-even point was passed in the 4th quarter of 2023
          </div>
        </div>
        <div class="timeline-item">
          <div class="line">
            <div class="check">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2454 2.61143C16.43 2.50035 16.6505 2.46513 16.8606 2.51314C17.0706 2.56115 17.254 2.68867 17.372 2.86893L18.197 4.12726C18.3046 4.29165 18.3506 4.48874 18.327 4.68375C18.3033 4.87877 18.2116 5.05916 18.0679 5.1931L18.0654 5.19643L18.0537 5.20726L18.0062 5.25143L17.8187 5.4306C16.7812 6.43693 15.7751 7.47521 14.802 8.54393C12.9712 10.5573 10.797 13.1923 9.33369 15.7489C8.92535 16.4623 7.92785 16.6156 7.33619 15.9998L1.93202 9.38476C1.85458 9.30427 1.7941 9.20902 1.7542 9.10469C1.7143 9.00035 1.69578 8.88906 1.69974 8.77743C1.70371 8.66579 1.73008 8.5561 1.77728 8.45486C1.82448 8.35362 1.89156 8.2629 1.97452 8.1881L2.60785 7.71476C2.75139 7.58536 2.93544 7.50986 3.1285 7.50119C3.32156 7.49253 3.51164 7.55123 3.66619 7.66726L7.42369 9.73476C11.7312 5.48726 14.1737 3.85726 16.2454 2.61143Z" fill="#77DA29"/>
              </svg>
            </div>
          </div>
          <div class="text">
            Established and growing market with a projected volume of $800B by 2026
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-block',
}
</script>

<style lang="less" scoped>
  .about-block {
    margin-bottom: 100px;

    > .title {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background-image: url("../images/about-bg.svg");
      background-position: top center;
      background-size: 632px;
      background-repeat: no-repeat;
      min-height: 158px;
      font-size: 70px;
      font-weight: 700;
      line-height: 77px;
      color: #fff;
      padding-top: 81px;
      margin-bottom: 15px;
    }
    > .igaming {
      color: #fff;

      > .text {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        text-align: center;
        padding: 0 220px;
        margin-bottom: 40px;
        color: rgba(255, 255, 255, 0.8);
        > strong {
          color: #fff;
          font-weight: 600;
        }
        &:before {
          content: '';
          display: block;
          width: 90px;
          height: 90px;
          position: absolute;
          left: 29px;
          top: 29px;
          background-image: url("../images/spade.png");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &:after {
          content: '';
          display: block;
          width: 90px;
          height: 90px;
          position: absolute;
          right: 45px;
          top: -29px;
          background-image: url("../images/diamond.png");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      > .timeline {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 94px;
        > .timeline-item {
          width: 150px;

          > .line {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            > .check {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                display: block;
                width: 100%;
                height: 2px;
                background: rgba(119, 218, 41, 1);
              }
            }
          }
          > .text {
            margin-top: 22px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .about-block {
      margin-bottom: 60px;

      > .title {
        background-size: 506px;
        min-height: 121px;
        font-size: 50px;
        line-height: 55px;
        padding-top: 66px;
        margin-bottom: 30px;
      }
      > .igaming {
        > .text {
          font-size: 14px;
          line-height: 25px;
          padding: 0 103px;
          margin-bottom: 35px;
          > strong {
            color: #fff;
            font-weight: 600;
          }
          &:before {
            width: 70px;
            height: 70px;
            left: 0;
            top: 41px;
          }
          &:after {
            width: 70px;
            height: 70px;
            right: 0;
            top: -30px;
          }
        }
        > .timeline {
          padding: 0;
          > .timeline-item {
            width: 124px;
            > .line {
              > .check {
                width: 30px;
                height: 20px;
                padding-bottom: 5px;
                padding-right: 10px;
              }
            }
            > .text {
              margin-top: 20px;
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .about-block {
      margin-bottom: 50px;

      > .title {
        background-size: 400px;
        min-height: 95px;
        font-size: 40px;
        line-height: 44px;
        padding-top: 51px;
        margin-bottom: 33px;
      }
      > .igaming {
        > .text {
          font-size: 14px;
          line-height: 25px;
          padding: 0 64px;
          margin-bottom: 35px;
          > strong {
            color: #fff;
            font-weight: 600;
          }
          &:before {
            width: 50px;
            height: 50px;
            left: 0;
            top: auto;
            bottom: 5px;
          }
          &:after {
            width: 50px;
            height: 50px;
            right: 0;
            top: -21px;
          }
        }
        > .timeline {
          padding: 0;
          > .timeline-item {
            width: 94px;
            > .line {
              > .check {
                width: 30px;
                height: 20px;
                padding-bottom: 5px;
                padding-right: 10px;
              }
            }
            > .text {
              margin-top: 20px;
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .about-block {
      margin-bottom: 60px;

      > .title {
        background-size: 274px;
        min-height: 70px;
        font-size: 32px;
        line-height: 35px;
        padding-top: 35px;
        margin-bottom: 7px;
      }
      > .igaming {
        > .text {
          font-size: 14px;
          line-height: 22px;
          padding: 0 20px;
          margin-bottom: 35px;
          &:before, &:after {
            display: none;
          }
        }
        > .timeline {
          padding: 0 20px;
          flex-direction: column;
          gap: 10px;
          > .timeline-item {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 10px;
            gap: 12px;
            &:last-child {
              border: 0;
            }
            > .line {
              width: auto;
              margin-bottom: 0;
              border: 0;
              > .check {
                width: 20px;
                height: 20px;
                padding: 0;
                > svg {
                  width: 20px;
                  height: 20px;
                }
                &:after {
                  display: none;
                }
              }
            }
            > .text {
              margin: 0;
              font-size: 14px;
              line-height: 22px;
              font-width: 400;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .about-block {
      margin-bottom: 50px;

      > .title {
        background-size: 240px;
        min-height: 61px;
        font-size: 28px;
        line-height: 31px;
        padding-top: 30px;
        margin-bottom: 9px;
      }
      > .igaming {
        > .text {
          font-size: 12px;
          line-height: 19px;
          padding: 0 15px;
          margin-bottom: 18px;
          &:before, &:after {
            display: none;
          }
        }
        > .timeline {
          padding: 0 15px;
          flex-direction: column;
          gap: 10px;
          > .timeline-item {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 10px;
            gap: 12px;
            &:last-child {
              border: 0;
            }
            > .line {
              width: auto;
              margin-bottom: 0;
              border: 0;
              > .check {
                width: 20px;
                height: 20px;
                padding: 0;
                > svg {
                  width: 20px;
                  height: 20px;
                }
                &:after {
                  display: none;
                }
              }
            }
            > .text {
              margin: 0;
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
</style>
