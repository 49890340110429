<template>
  <div class="home-page">
    <header-block></header-block>
    <div class="container">
<!--      <nto-block></nto-block>-->
      <about-block></about-block>
      <games-block></games-block>
      <projects-block></projects-block>
      <status-card-block></status-card-block>
    </div>
    <social-block></social-block>
  </div>
</template>

<script>
import HeaderBlock from './snippets/HeaderBlock.vue'
import NtoBlock from './snippets/NtoBlock.vue'
import AboutBlock from './snippets/AboutBlock.vue'
import GamesBlock from './snippets/GamesBlock.vue'
import ProjectsBlock from './snippets/ProjectsBlock.vue'
import StatusCardBlock from './snippets/StatusCardsBlock.vue'
import SocialBlock from './snippets/SocialBlock.vue'

export default {
  components: {
    'header-block': HeaderBlock,
    'nto-block': NtoBlock,
    'about-block': AboutBlock,
    'games-block': GamesBlock,
    'projects-block': ProjectsBlock,
    'status-card-block': StatusCardBlock,
    'social-block': SocialBlock,
  },
  mounted() {
    // scroll click
    const userSelection = document.getElementsByClassName('scroll-click');

    for(let n = 0; n < userSelection.length; n++) {
      (function(index) {
        userSelection[index].addEventListener("click", function(e) {
          e.preventDefault();
          let scrollElement = document.getElementsByClassName('games-block-item')[n];

          let bodyRect = document.body.getBoundingClientRect(),
              elemRect = scrollElement.getBoundingClientRect(),
              pos = elemRect.top - bodyRect.top - 60;
          let currentPos = window.scrollY;
          let start = null;
          let time = 500;
          pos = +pos, time = +time;
          window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start;
            let progress = currentTime - start;
            if (currentPos < pos) {
              window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
            } else {
              window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
            }
            if (progress < time) {
              window.requestAnimationFrame(step);
            } else {
              window.scrollTo(0, pos);
            }
          });
        })
      })(n);
    }
  }
}
</script>

<style lang="less">
.home-page {
  background: #001A54;
  .container {
    width: 1180px;
    margin: 0 auto;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 1439px) {
    .container {
      width: 850px;
    }
  }
  @media screen and (max-width: 1023px) {
    .container {
      width: 670px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      width: 100%;
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 429px) {
    .container {
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>
