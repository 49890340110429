<template>
  <div class="games-block">
    <div class="games-block-item">
      <div class="text mobile">
        <div class="title">
          TRUE Games
        </div>
        <div class="date">
          Founded in 2018
        </div>
      </div>
      <div class="image">
        <img :src="require('../images/games-true-games.png')" alt="TRUE Games">
      </div>
      <div class="text">
        <div class="title">
          TRUE Games
        </div>
        <div class="date">
          Founded in 2018
        </div>
        <div class="description">
          <p><a href="https://truelab.games/" target="_blank">TrueLab Game Studios</a> is a globally recognized game developer specializing in creating unique content for the Gaming & GameFi industries using Web3 technologies.</p>
          <p>TrueLab is a game-changer in introducing NFTs to the industry by fully integrating the tokens into the gameplay, which significantly enhances customer retention, improving user metrics, and driving substantial revenue growth.</p>
        </div>
        <div class="cards">
          <div class="cards-item">
            <div class="value">5M+</div>
            <div class="title">total players</div>
          </div>
          <div class="cards-item">
            <div class="value">500K+</div>
            <div class="title">Monthly active users (MAU)</div>
          </div>
          <div class="cards-item">
            <div class="value">$50M+</div>
            <div class="title">Monthly transaction volume</div>
          </div>
          <div class="cards-item">
            <div class="value">$2.5M+</div>
            <div class="title">Monthly gross gaming revenue</div>
          </div>
          <div class="cards-item">
            <div class="value">$200K+</div>
            <div class="title">Monthly net revenue</div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-block-item">
      <div class="text mobile">
        <div class="title">
          TRUE App
        </div>
        <div class="date">
          Founded in 2021
        </div>
        <div class="stores">
          <div class="stores-item">
            <img :src="require('../images/app-store.svg')" alt="App Store">
          </div>
          <div class="stores-item">
            <img :src="require('../images/google-play.svg')" alt="Google Play">
          </div>
        </div>
      </div>
      <div class="image">
        <img :src="require('../images/games-true-app.png')" alt="TRUE App">
      </div>
      <div class="text">
        <div class="title">
          TRUE App
          <div class="stores">
            <div class="stores-item">
              <img :src="require('../images/app-store.svg')" alt="App Store">
            </div>
            <div class="stores-item">
              <img :src="require('../images/google-play.svg')" alt="Google Play">
            </div>
          </div>
        </div>
        <div class="date">
          Founded in 2021
        </div>
        <div class="description">
          <p><strong>TRUE App</strong> is a B2B blockchain-driven system for Events, Sports, Gaming & GameFi and other Web2 businesses. It's a game-changer in enhancing customer retention, improving user metrics, and driving substantial revenue growth. At its core, a cryptocurrency wallet that serves as a catalyst for the mass adoption of Web3, offering an NFT marketplace, on-ramp services, KYC, and additional features like crypto prize drops to facilitate the widespread acceptance of cryptocurrencies.</p>
          <p>Integrating this solution into the <a href="https://softswiss.com/" target="_blank">SoftSwiss ecosystem</a> is poised to revolutionise the Web3 market, leveraging unparalleled access to the iGaming market's user liquidity. This strategic move significantly enhances our leadership by expanding transaction and user volumes, setting a new benchmark for innovation and mass adoption in the Web3 space.</p>
        </div>
        <div class="cards">
          <div class="cards-item">
            <div class="value">250K+</div>
            <div class="title">Wallets created</div>
          </div>
          <div class="cards-item">
            <div class="value">200K+</div>
            <div class="title">NFT owners</div>
          </div>
          <div class="cards-item">
            <div class="value">$2,5M+</div>
            <div class="title">Marketplace volume</div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-block-item">
      <div class="text mobile">
        <div class="title">
          TRUE World
        </div>
        <div class="date">
          SocialFi Gaming Meta
        </div>
      </div>
      <div class="image">
        <img :src="require('../images/games-true-world.png')" alt="TRUE World">
      </div>
      <div class="text">
        <div class="title">
          TRUE World
        </div>
        <div class="date">
          SocialFi Gaming Meta
        </div>
        <div class="description">
          <p><a href="https://true.world/" target="_blank">TRUE World</a> is a <strong>SocialFi Gaming Meta</strong> where players use slot mechanics to develop virtual worlds styled after various narratives from the Truelab Games portfolio <strong>just for free</strong>. A slot machine is the central element of the game, allowing players to <strong>earn Truecoins</strong>, the main game currency in TRUE World. Players can exchange <strong>Truecoin</strong> for <strong>$TRUE</strong>. The game integrates with the <strong>TON blockchain</strong> and offers a deep social interconnection and economic strategy, allowing players to create and manage clans, participate in clan wars, and trade valuable NFT assets.</p>
          <p><strong>Truecoin</strong> is a key part of <strong>TRUE World</strong>. This is a free play2earn <strong>Telegram game</strong>. Users farm game coins by tapping the screen.</p>
        </div>
        <div class="link">
          <div class="text">Try our TRUE World App in telegram</div>
          <a href="https://t.me/true_coin_bot" target="_blank">
            Go to App
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.353259 9.64455L4.96166 11.3646L6.74539 17.101C6.85953 17.4684 7.30879 17.6042 7.60699 17.3604L10.1758 15.2663C10.4451 15.0469 10.8286 15.036 11.11 15.2402L15.7432 18.604C16.0622 18.8359 16.5141 18.6611 16.5941 18.2756L19.9882 1.94955C20.0755 1.52849 19.6618 1.17722 19.2609 1.33229L0.347859 8.62835C-0.118874 8.80835 -0.114808 9.46915 0.353259 9.64455ZM6.45793 10.449L15.4645 4.90182C15.6263 4.80242 15.7929 5.02129 15.6539 5.15022L8.22086 12.0596C7.95959 12.3028 7.79106 12.6282 7.74333 12.9815L7.49013 14.8579C7.45659 15.1084 7.10466 15.1333 7.03553 14.8908L6.06173 11.4692C5.95019 11.0789 6.11273 10.662 6.45793 10.449Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'games-block',
}
</script>

<style lang="less" scoped>
  .games-block {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 110px;
    .games-block-item {
      display: flex;
      flex-direction: row;
      gap: 50px;
      width: 100%;
      align-items: flex-start;
      > .image {
        display: block;
        width: 50%;
        > img {
          width: 100%;
        }
      }
      > .text {
        &.mobile {
          display: none;
        }
        display: flex;
        flex-direction: column;
        color: #fff;
        width: 50%;
        > .title {
          font-size: 40px;
          font-weight: 700;
          line-height: 52px;
          margin-bottom: 2px;
          display: flex;
          gap: 20px;
          align-items: center;
          > .stores {
            display: flex;
            gap: 5px;
            > .stores-item {
              position: relative;
              > img {
                display: block;
              }
              &:before {
                content: 'soon';
                position: absolute;
                top: -6px;
                right: -2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 12px;
                font-size: 8px;
                font-weight: 600;
                line-height: 10px;
                border-radius: 6px 6px 6px 0;
                background: #637396;
                color: #001A54;
                padding-top: 2px;
              }
            }
          }
        }
        > .date {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          opacity: 0.6;
          margin-bottom: 16px;
        }
        > .description {
          margin-bottom: 30px;
          > p {
            color: #FFFFFFCC;
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
            margin-bottom: 16px;
            > strong {
              color: #fff;
              font-weight: 600;
            }
            > a {
              border-bottom: 1px solid #536389;
              color: #fff;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
        > .cards {
          display: flex;
          flex-direction: row;
          gap: 12px;
          flex-wrap: wrap;
          justify-content: flex-start;
          > .cards-item {
            background: rgba(255, 255, 255, 0.06);
            border-radius: 12px;
            width: 31%;
            padding: 12px 20px;
            &:nth-child(3) {
              padding-right: 12px;
            }
            > .value {
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
            }
            > .title {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        > .link {
          display: flex;
          flex-direction: column;
          > .text {
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #fff;
            margin-bottom: 14px;
          }
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 10px;
            padding: 0 40px;
            width: 190px;
            height: 60px;
            font-size: 16px;
            font-weight: 600;
            line-height: 29px;
            color: #fff;
            background: linear-gradient(102.48deg, #1079D9 8.34%, #3B89FE 111.45%);
            border-radius: 100px;
            &:hover {
              background: linear-gradient(102.48deg, #3194f0 8.34%, #62a1fe 111.45%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .games-block {
      gap: 76px;
      margin-bottom: 80px;
      padding: 0 10px;
      .games-block-item {
        gap: 30px;
        > .text {
          > .title {
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 4px;
          }
          > .date {
            font-size: 13px;
            line-height: 21px;
            margin-bottom: 14px;
          }
          > .description {
            margin-bottom: 6px;
            > p {
              font-size: 14px;
              line-height: 25px;
              margin-bottom: 14px;
            }
          }
          > .cards {
            gap: 8px;
            > .cards-item {
              padding: 16px 18px;
              > .value {
                font-size: 18px;
                line-height: 27px;
              }
              > .title {
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
          > .link {
            > .text {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 12px;
            }
            > a {
              gap: 10px;
              padding: 0 35px;
              width: 170px;
              height: 50px;
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .games-block {
      gap: 60px;
      margin-bottom: 70px;
      padding: 0;
      .games-block-item {
        gap: 20px;
        &:nth-child(2) {
          > .text {
            > .cards {
              > .cards-item:nth-child(3) {
                width: 100%;
              }
            }
          }
        }
        > .text {
          > .title {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 1px;
          }
          > .date {
            font-size: 12px;
            line-height: 19px;
            margin-bottom: 10px;
          }
          > .description {
            margin-bottom: 4px;
            > p {
              font-size: 14px;
              line-height: 25px;
              margin-bottom: 14px;
            }
          }
          > .cards {
            gap: 8px;
            > .cards-item {
              width: calc( 50% - 8px );
              padding: 12px 18px;
              > .value {
                font-size: 16px;
                line-height: 24px;
              }
              > .title {
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
          > .link {
            > .text {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 12px;
            }
            > a {
              gap: 10px;
              padding: 0 35px;
              width: 170px;
              height: 50px;
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .games-block {
      margin-bottom: 60px;
      gap: 60px;
      > .games-block-item {
        flex-direction: column;
        gap: 0;
        &:nth-child(2) {
          > .text {
            > .cards {
              > .cards-item:nth-child(3) {
                width: 100%;
              }
            }
          }
        }
        > .text.mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          > .title {
            display: block;
            font-size: 26px;
            line-height: 34px;
          }
          > .date {
            display: block;
            margin-bottom: 10px;
            font-size: 13px;
            line-height: 20px;
          }
          > .stores {
            margin-top: 6px;
            display: flex;
            gap: 5px;
            > .stores-item {
              position: relative;
              > img {
                display: block;
              }
              &:before {
                content: 'soon';
                position: absolute;
                top: -6px;
                right: -2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 12px;
                font-size: 8px;
                font-weight: 600;
                line-height: 10px;
                border-radius: 6px 6px 6px 0;
                background: #637396;
                color: #001A54;
                padding-top: 2px;
              }
            }
          }
        }
        > .image {
          width: 100%;
          padding: 0 20px;
          > img {
            width: 100%;
          }
        }
        > .text {
          width: 100%;
          > .title { display: none; }
          > .date { display: none; }
          > .cards {
            gap: 6px;
            .cards-item {
              width: calc(50% - 3px);
              padding: 12px 15px;
              > .value {
                font-size: 14px;
                line-height: 21px;
              }
              > .title {
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
          > .description {
            margin-bottom: 20px;
            padding: 0 20px;
            > p {
              font-size: 14px;
              line-height: 22px;
            }
          }
          > .link {
            padding: 0 20px;
            > .text {
              margin-bottom: 10px;
              font-size: 14px;
              line-height: 22px;
            }
            > a {
              width: 100%;
              height: 50px;
              gap: 10px;
              font-size: 14px;
              line-height: 25px;
              > svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .games-block {
      margin-bottom: 50px;
      gap: 50px;
      > .games-block-item {
        flex-direction: column;
        gap: 0;
        &:nth-child(2) {
          > .text {
            > .cards {
              > .cards-item:nth-child(3) {
                width: 100%;
              }
            }
          }
        }
        > .text.mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          > .title {
            display: block;
            font-size: 20px;
            line-height: 26px;
          }
          > .date {
            display: block;
            margin-bottom: 4px;
            font-size: 11px;
            line-height: 17px;
          }
          > .stores {
            margin-top: 6px;
            display: flex;
            gap: 5px;
            > .stores-item {
              position: relative;
              > img {
                display: block;
              }
              &:before {
                content: 'soon';
                position: absolute;
                top: -6px;
                right: -2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 12px;
                font-size: 8px;
                font-weight: 600;
                line-height: 10px;
                border-radius: 6px 6px 6px 0;
                background: #637396;
                color: #001A54;
                padding-top: 2px;
              }
            }
          }
        }
        > .image {
          width: 100%;
          padding: 0 15px;
          > img {
            width: 100%;
          }
        }
        > .text {
          width: 100%;
          > .title { display: none; }
          > .date { display: none; }
          > .cards {
            gap: 6px;
            .cards-item {
              width: calc(50% - 3px);
              padding: 12px 15px;
              > .value {
                font-size: 14px;
                line-height: 21px;
              }
              > .title {
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
          > .description {
            margin-bottom: 16px;
            padding: 0 15px;
            > p {
              font-size: 12px;
              line-height: 19px;
            }
          }
          > .link {
            padding: 0 15px;
            > .text {
              margin-bottom: 10px;
              font-size: 12px;
              line-height: 19px;
            }
            > a {
              width: 100%;
              height: 40px;
              gap: 10px;
              font-size: 13px;
              line-height: 23px;
              > svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>
