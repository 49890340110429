<template>
  <div class="social-block">
    <div class="container">
      <div class="title">
        Join our community
        <span>to stay tuned</span>
      </div>
      <div class="links">
        <a href="https://t.me/true_ecosystem" target="_blank" class="telegram">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_845_11313)">
              <path d="M0.853259 9.6426L5.46166 11.3626L7.24539 17.0991C7.35953 17.4665 7.80879 17.6023 8.10699 17.3585L10.6758 15.2643C10.9451 15.0449 11.3286 15.034 11.61 15.2383L16.2432 18.6021C16.5622 18.8339 17.0141 18.6591 17.0941 18.2737L20.4882 1.9476C20.5755 1.52653 20.1618 1.17527 19.7609 1.33033L0.847859 8.6264C0.381126 8.8064 0.385192 9.4672 0.853259 9.6426ZM6.95793 10.447L15.9645 4.89987C16.1263 4.80047 16.2929 5.01933 16.1539 5.14827L8.72086 12.0576C8.45959 12.3008 8.29106 12.6263 8.24333 12.9795L7.99013 14.8559C7.95659 15.1065 7.60466 15.1313 7.53553 14.8889L6.56173 11.4672C6.45019 11.0769 6.61273 10.6601 6.95793 10.447Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_845_11313">
                <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg>
          TG Channel
        </a>
        <a href="https://t.me/true_community" target="_blank" class="telegram">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_845_11313)">
              <path d="M0.853259 9.6426L5.46166 11.3626L7.24539 17.0991C7.35953 17.4665 7.80879 17.6023 8.10699 17.3585L10.6758 15.2643C10.9451 15.0449 11.3286 15.034 11.61 15.2383L16.2432 18.6021C16.5622 18.8339 17.0141 18.6591 17.0941 18.2737L20.4882 1.9476C20.5755 1.52653 20.1618 1.17527 19.7609 1.33033L0.847859 8.6264C0.381126 8.8064 0.385192 9.4672 0.853259 9.6426ZM6.95793 10.447L15.9645 4.89987C16.1263 4.80047 16.2929 5.01933 16.1539 5.14827L8.72086 12.0576C8.45959 12.3008 8.29106 12.6263 8.24333 12.9795L7.99013 14.8559C7.95659 15.1065 7.60466 15.1313 7.53553 14.8889L6.56173 11.4672C6.45019 11.0769 6.61273 10.6601 6.95793 10.447Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_845_11313">
                <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg>
          TG Chat
        </a>
        <a href="https://x.com/truexworld" target="_blank" class="twitter">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_845_11323)">
              <path d="M20 3.79875C19.2563 4.125 18.4637 4.34125 17.6375 4.44625C18.4875 3.93875 19.1363 3.14125 19.4412 2.18C18.6488 2.6525 17.7738 2.98625 16.8412 3.1725C16.0887 2.37125 15.0162 1.875 13.8462 1.875C11.5763 1.875 9.74875 3.7175 9.74875 5.97625C9.74875 6.30125 9.77625 6.61375 9.84375 6.91125C6.435 6.745 3.41875 5.11125 1.3925 2.6225C1.03875 3.23625 0.83125 3.93875 0.83125 4.695C0.83125 6.115 1.5625 7.37375 2.6525 8.1025C1.99375 8.09 1.3475 7.89875 0.8 7.5975C0.8 7.61 0.8 7.62625 0.8 7.6425C0.8 9.635 2.22125 11.29 4.085 11.6712C3.75125 11.7625 3.3875 11.8062 3.01 11.8062C2.7475 11.8062 2.4825 11.7913 2.23375 11.7362C2.765 13.36 4.2725 14.5538 6.065 14.5925C4.67 15.6838 2.89875 16.3412 0.98125 16.3412C0.645 16.3412 0.3225 16.3263 0 16.285C1.81625 17.4563 3.96875 18.125 6.29 18.125C13.835 18.125 17.96 11.875 17.96 6.4575C17.96 6.27625 17.9538 6.10125 17.945 5.9275C18.7588 5.35 19.4425 4.62875 20 3.79875Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_845_11323">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Twitter
        </a>
<!--        <a href="https://www.instagram.com/true_ecosystem/" target="_blank" class="instagram">-->
<!--          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M9.99725 6.66525C8.16101 6.66525 6.6625 8.16376 6.6625 10C6.6625 11.8362 8.16101 13.3348 9.99725 13.3348C11.8335 13.3348 13.332 11.8362 13.332 10C13.332 8.16376 11.8335 6.66525 9.99725 6.66525ZM19.999 10C19.999 8.61907 20.0115 7.25064 19.934 5.87221C19.8564 4.27113 19.4912 2.85017 18.3204 1.67938C17.1471 0.506085 15.7286 0.14334 14.1275 0.065788C12.7466 -0.0117644 11.3782 0.000744113 9.99975 0.000744113C8.61882 0.000744113 7.2504 -0.0117644 5.87197 0.065788C4.27089 0.14334 2.84993 0.508587 1.67913 1.67938C0.505841 2.85267 0.143096 4.27113 0.0655439 5.87221C-0.0120085 7.25314 0.000499972 8.62157 0.000499972 10C0.000499972 11.3784 -0.0120085 12.7494 0.0655439 14.1278C0.143096 15.7289 0.508343 17.1498 1.67913 18.3206C2.85243 19.4939 4.27089 19.8567 5.87197 19.9342C7.2529 20.0118 8.62132 19.9993 9.99975 19.9993C11.3807 19.9993 12.7491 20.0118 14.1275 19.9342C15.7286 19.8567 17.1496 19.4914 18.3204 18.3206C19.4937 17.1473 19.8564 15.7289 19.934 14.1278C20.014 12.7494 19.999 11.3809 19.999 10ZM9.99725 15.131C7.15784 15.131 4.86629 12.8394 4.86629 10C4.86629 7.16058 7.15784 4.86903 9.99725 4.86903C12.8367 4.86903 15.1282 7.16058 15.1282 10C15.1282 12.8394 12.8367 15.131 9.99725 15.131ZM15.3384 5.8572C14.6754 5.8572 14.1401 5.32184 14.1401 4.65889C14.1401 3.99594 14.6754 3.46058 15.3384 3.46058C16.0013 3.46058 16.5367 3.99594 16.5367 4.65889C16.5369 4.81631 16.506 4.97222 16.4459 5.1177C16.3857 5.26317 16.2974 5.39535 16.1861 5.50666C16.0748 5.61798 15.9426 5.70624 15.7972 5.76639C15.6517 5.82654 15.4958 5.8574 15.3384 5.8572Z" fill="white"/>-->
<!--          </svg>-->
<!--          Instagram-->
<!--        </a>-->
<!--        <a href="https://discord.gg/xUPvjawmUa" target="_blank" class="discord">-->
<!--          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15.2477 3.17085C14.0825 2.6257 12.8367 2.2295 11.5342 2.00391C11.3742 2.29311 11.1873 2.68211 11.0585 2.99155C9.67387 2.78332 8.30201 2.78332 6.94287 2.99155C6.81404 2.68211 6.62292 2.29311 6.46152 2.00391C5.15761 2.2295 3.91032 2.62716 2.74514 3.17374C0.394982 6.72514 -0.242108 10.1883 0.0764375 13.6024C1.63519 14.7664 3.14581 15.4735 4.63093 15.9362C4.99762 15.4316 5.32465 14.8951 5.60638 14.3297C5.06981 14.1258 4.5559 13.8742 4.07031 13.5821C4.19913 13.4867 4.32514 13.3869 4.44689 13.2842C7.40865 14.6695 10.6267 14.6695 13.5531 13.2842C13.6762 13.3869 13.8022 13.4867 13.9296 13.5821C13.4426 13.8756 12.9273 14.1273 12.3907 14.3312C12.6724 14.8951 12.9981 15.433 13.3662 15.9377C14.8527 15.4749 16.3647 14.7678 17.9235 13.6024C18.2973 9.64464 17.285 6.21325 15.2477 3.17085ZM6.00988 11.5028C5.12079 11.5028 4.39166 10.6727 4.39166 9.66198C4.39166 8.65122 5.10522 7.81977 6.00988 7.81977C6.91457 7.81977 7.64367 8.64977 7.6281 9.66198C7.62951 10.6727 6.91457 11.5028 6.00988 11.5028ZM11.9901 11.5028C11.101 11.5028 10.3718 10.6727 10.3718 9.66198C10.3718 8.65122 11.0854 7.81977 11.9901 7.81977C12.8947 7.81977 13.6238 8.64977 13.6083 9.66198C13.6083 10.6727 12.8947 11.5028 11.9901 11.5028Z" fill="white"/>-->
<!--          </svg>-->
<!--          Discord-->
<!--        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'social-block',
}
</script>

<style lang="less" scoped>
  .social-block {
    position: relative;
    top: -206px;
    padding-top: 509px;
    margin-bottom: -206px;
    min-height: 810px;
    background-image: url("../images/bg-social.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 2560px;
    > .container {
      > .title {
        font-size: 60px;
        font-weight: 700;
        line-height: 90px;
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
        color: #fff;
        > span {
          display: block;
          font-size: 26px;
          font-weight: 300;
          line-height: 39px;
          letter-spacing: 0.3em;
          text-align: center;
          color: rgba(255,255,255,0.8);
        }
      }
      > .links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 98px;
        gap: 6px;
        > a {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 160px;
          height: 50px;
          border-radius: 100px;
          color: #fff;
          &.telegram {
            background: linear-gradient(102.48deg, #1079D9 8.34%, #3B89FE 111.45%);
            &:hover {
              background: linear-gradient(102.48deg, #3194f0 8.34%, #62a1fe 111.45%);
            }
          }
          &.twitter {
            background: linear-gradient(102.48deg, #108ED9 8.34%, #55BDFF 111.45%);
            &:hover {
              background: linear-gradient(102.48deg, #31a8f0 8.34%, #77caff 111.45%);
            }
          }
          &.instagram {
            background: linear-gradient(146.41deg, #4367CA -9.09%, #B431BA 33.17%, #F24D56 70.67%, #FFCB52 129.44%);
            &:hover {
              background: linear-gradient(146.41deg, #6985d5 -9.09%, #cb51d1 33.17%, #f57178 70.67%, #ffd575 129.44%);
            }
          }
          &.discord {
            background: linear-gradient(102.48deg, #4450E4 8.34%, #313FEC 111.45%);
            &:hover {
              background: linear-gradient(102.48deg, #6973e9 8.34%, #5a65f0 111.45%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .social-block {
      position: relative;
      top: -206px;
      padding-top: 437px;
      margin-bottom: -202px;
      min-height: 692px;
      background-size: 2186px;
      > .container {
        > .title {
          font-size: 40px;
          line-height: 60px;
          margin-bottom: 23px;
          > span {
            font-size: 20px;
            line-height: 30px;
          }
        }
        > .links {
          margin-bottom: 92px;
          gap: 6px;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 150px;
            height: 50px;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .social-block {
      position: relative;
      top: -166px;
      padding-top: 347px;
      margin-bottom: -166px;
      min-height: 548px;
      background-size: 1730px;
      > .container {
        > .title {
          font-size: 34px;
          line-height: 51px;
          margin-bottom: 26px;
          > span {
            font-size: 18px;
            line-height: 27px;
          }
        }
        > .links {
          margin-bottom: 55px;
          gap: 5px;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            width: 149px;
            height: 41px;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .social-block {
      position: relative;
      top: -293px;
      padding-top: 473px;
      margin-bottom: -293px;
      min-height: 780px;
      background-size: 2462px;
      > .container {
        > .title {
          font-size: 26px;
          line-height: 39px;
          margin-bottom: 20px;
          > span {
            font-size: 18px;
            line-height: 27px;
          }
        }
        > .links {
          margin-bottom: 87px;
          gap: 10px;
          flex-wrap: wrap;
          > a {
            gap: 6px;
            width: 150px;
            height: 40px;
            font-size: 12px;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .social-block {
      position: relative;
      top: -223px;
      padding-top: 363px;
      margin-bottom: -223px;
      min-height: 600px;
      background-size: 1894px;
      > .container {
        > .title {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 16px;
          > span {
            font-size: 14px;
            line-height: 21px;
          }
        }
        > .links {
          margin-bottom: 44px;
          gap: 8px;
          flex-wrap: wrap;
          > a {
            gap: 6px;
            width: 126px;
            height: 38px;
            font-size: 12px;
            line-height: 21px;
          }
        }
      }
    }
  }
</style>
