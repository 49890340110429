<template>
  <div class="nto-block">
    <div class="nto-block-bg">
      <div class="image">
        <img :src="require('../images/loyalty-nto-image.png')">
      </div>
      <div class="content">
        <div class="text">
          <div class="title">
            Join TRUE Loyalty NTO
          </div>
          <p>
            <img :src="require('../images/loyalty-nto-image.png')" class="mobile">
            <span>Join in, get the guaranteed tokens and enter the prize draw worth over <strong>$300,000</strong>.</span>
          </p>
        </div>
        <router-link :to="{ name: 'true-nto' }" class="button">
          Join now
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nto-block',
}
</script>

<style lang="less" scoped>
  .nto-block {
    margin-top: 42px;
    margin-bottom: 142px;

    .nto-block-bg {
      display: flex;
      height: 200px;
      background-image: url("../images/bg-loyalty-nto.png");
      background-repeat: no-repeat;
      padding: 0 50px 0 54px;
      gap: 66px;
      align-items: center;
      color: #fff;
      border-radius: 30px;
      > .image {
        position: relative;
        width: 330px;
        height: 200px;
        > img {
          position: absolute;
          top: -42px;
          left: 0;
          width: 330px;
        }
      }
      > .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        gap: 77px;
        > .text {
          flex: 1;
          > .title {
            font-size: 36px;
            font-weight: 700;
            line-height: 54px;
          }
          > p {
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
          }
        }
        > .button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(85.73deg, #FCB42F -25.39%, #CB34E3 114.43%);
          border-radius: 100px;
          width: 190px;
          height: 60px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-decoration: none;
          color: #FFF;
          &:hover {
            background: linear-gradient(85.73deg, #fce72f -25.39%, #df52f5 114.43%);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .nto-block {
      margin-top: 28px;
      margin-bottom: 136px;

      .nto-block-bg {
        height: 144px;
        padding: 0 40px 0 28px;
        gap: 29px;
        > .image {
          width: 236px;
          height: 144px;
          > img {
            position: absolute;
            top: -28px;
            left: 0;
            width: 236px;
          }
        }
        > .content {
          gap: 50px;
          > .text {
            > .title {
              font-size: 26px;
              line-height: 39px;
            }
            > p {
              font-size: 14px;
              line-height: 21px;
            }
          }
          > .button {
            width: 150px;
            height: 50px;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .nto-block {
      margin-top: 15px;
      margin-bottom: 106px;

      .nto-block-bg {
        height: 144px;
        padding: 0 35px 0 20px;
        gap: 22px;
        > .image {
          width: 210px;
          height: 144px;
          > img {
            position: absolute;
            top: -15px;
            left: 0;
            width: 210px;
          }
        }
        > .content {
          gap: 31px;
          > .text {
            > .title {
              font-size: 20px;
              line-height: 30px;
            }
            > p {
              font-size: 13px;
              line-height: 20px;
            }
          }
          > .button {
            width: 120px;
            height: 40px;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .nto-block {
      margin-top: 0;
      margin-bottom: 60px;

      .nto-block-bg {
        background-image: url("../images/bg-loyalty-nto-mobile.png");
        background-size: cover;
        background-position: top center;
        height: 310px;
        padding: 0;
        gap: 10px;
        flex-direction: column;
        border-radius: 20px 20px 0 0;
        > .image {
          display: none;
          width: 210px;
          height: 144px;
          > img {
            position: absolute;
            top: -15px;
            left: 0;
            width: 210px;
          }
        }
        > .content {
          flex-direction: column;
          gap: 20px;
          padding: 22px 20px 33px 20px;
          background: linear-gradient(0deg, #001A54 0%, rgba(115, 115, 115, 0) 100%);
          border-radius: 20px 20px 0 0;

          > .text {
            > .title {
              text-align: center;
              font-size: 24px;
              line-height: 36px;
              margin-bottom: 10px;
            }
            > p {
              display: flex;
              align-items: center;
              gap: 20px;
              font-size: 14px;
              line-height: 23px;
              padding-right: 6px;
              > img {
                display: block;
                width: 164px;
              }
            }
          }
          > .button {
            width: 100%;
            height: 50px;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 429px) {
    .nto-block {
      margin-top: 0;
      margin-bottom: 60px;

      .nto-block-bg {
        background-image: url("../images/bg-loyalty-nto-mobile.png");
        background-size: cover;
        background-position: top center;
        height: 230px;
        padding: 0;
        gap: 10px;
        flex-direction: column;
        border-radius: 20px 20px 0 0;
        > .image {
          display: none;
          width: 210px;
          height: 144px;
          > img {
            position: absolute;
            top: -15px;
            left: 0;
            width: 210px;
          }
        }
        > .content {
          flex-direction: column;
          gap: 20px;
          padding: 21px 15px 10px 15px;
          background: linear-gradient(0deg, #001A54 0%, rgba(115, 115, 115, 0) 100%);
          border-radius: 16px 16px 0 0;

          > .text {
            > .title {
              text-align: center;
              font-size: 18px;
              line-height: 27px;
              margin-bottom: 10px;
            }
            > p {
              display: flex;
              align-items: center;
              gap: 14px;
              font-size: 12px;
              line-height: 18px;
              padding-right: 0;
              > img {
                display: block;
                width: 124px;
              }
            }
          }
          > .button {
            width: 100%;
            height: 40px;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
